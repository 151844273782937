var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticStyle:{"min-height":"45rem"}},[_c('el-table',{attrs:{"data":_vm.list},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('el-pagination',{staticStyle:{"padding":".5rem"},attrs:{"background":"","layout":"total,prev, pager,next","total":_vm.total,"page-size":15,"current-page":_vm.page},on:{"current-change":_vm.pageChange}})]},proxy:true}])},[_c('el-table-column',{attrs:{"prop":"id","label":"编号","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"uid","label":"用户编号","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"nickname","label":"用户昵称","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"title","label":"讲师名称","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"pic","label":"讲师头像","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(s){return [_c('img',{staticStyle:{"height":"3rem","width":"3rem","border":".5rem"},attrs:{"src":_vm._f("tomedia")(s.row.pic),"alt":""}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(s){return [_c('el-button',{on:{"click":function($event){return _vm.$router.push({
        name:'addonsKnowledgeTeacherAward',
        query:{
          id:s.row.id
        }
        })}}},[_vm._v("佣金记录")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
        name:'addonsKnowledgeTeacherEdit',
        query:{
          id:s.row.id
        }
        })}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.remove(s.row)}}},[_vm._v("删除")])]}},{key:"header",fn:function(){return [_c('el-button',{on:{"click":function($event){return _vm.$router.push({
        name:'addonsKnowledgeTeacherEdit',
        })}}},[_vm._v("添加讲师")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }